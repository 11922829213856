/**
 * Respect Tiny is a simple version of Respect - a HTML5 Canvas responsive handler develop by Les Affranchis
 *
 * @author: Martin Tremblay - martin@lesaffranchis.ca
 */
import EventEmitter from "eventemitter3";

export default class RespectTiny extends EventEmitter
{
    constructor ()
    {
        if (RespectTiny.instance)
        {
            throw("Don't instantiate RespectTiny");
        }

        super();

        RespectTiny.instance = this;

        RespectTiny.a11yMultiplier = 1;

        window.onresize = () => this.onResize();


        //THIS IS A FIX FOR CHROME ON iOS. The current version (2021-04-15) has a bug.
        this.orientation = "";
        this.checkForOrientationChange ();

        this.arrResponsives = [];
    }

    addNewResponsive (obj)
    {
        this.arrResponsives.push(obj);
    }

    onResize ()
    {
        if (!this.arrResponsives)
            return;

        for (let i = 0; i < this.arrResponsives.length; i++)
        {
            let objResponsive = this.arrResponsives[i];

            if (objResponsive.callback)
            {
                objResponsive.callback(objResponsive.responsiveDisplay, objResponsive.responsive);
            }

        }
    }

    checkForOrientationChange ()
    {
        requestAnimationFrame( () => this.checkForOrientationChange())
        let width = window.innerWidth;
        let height = window.innerHeight;
        let ratio = width / height;
        let orientation = ratio > 1 ? "landscape" : "portrait";


        if (this.orientation !== orientation)
        {
            this.orientation = orientation;
            this.onOrientationChange();
        }
    }


    onOrientationChange ()
    {
        //There is a bug on chrome (as of 2021-04-15), this is the fastest way to ensure we detect the orientation change
        setTimeout(() => this.onResize(), 200);
        setTimeout(() => this.onResize(), 400);
        setTimeout(() => this.onResize(), 600);
        setTimeout(() => this.onResize(), 800);
        setTimeout(() => this.onResize(), 1000);
    }


    static addResponsive (obj, responsive, callback)
    {
        RespectTiny.instance.addNewResponsive(obj);
    }
}


let __respect__ = new RespectTiny();