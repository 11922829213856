import * as PIXI from "pixi.js";

export default class Syllable extends PIXI.Container
{
    constructor(strSyllable)
    {
        super();

        this.strSyllable = strSyllable;

        this.build ();
    }

    build ()
    {
        const style = new PIXI.TextStyle({
            fontFamily: "Poppins",
            fontSize: 27,
            stroke:"#FF0000"
        });


        this.text = new PIXI.Text(this.strSyllable, style);
        this.text.anchor.x = 0.5;


        this.addChild(this.text);

    }
}