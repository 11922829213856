import Base from "./Base.js";
import Library from "../Library.js";
import WaveSurfer from "wavesurfer";
import * as PIXI from "pixi.js";
import Display from "./utils/Display.js";
import {gsap} from 'gsap';
import { DropShadowFilter, GlowFilter } from 'pixi-filters';

export default class Game extends Base
{

    constructor(strSelectorCanvas)
    {
        super(strSelectorCanvas);
        

        this.nextSectionStop = 0;
        this.currentSyllableHighlighted = null;
        this.karaokeOver = false;
    }


    buildApp ()
    {
        this.nextSectionStop = 0;
        super.buildApp();

    }

    prepareRhythm ()
    {


        let player1 = this.playersHits.player1;

        let firstSectionEnd = this.getHitFromPosition(this.playersHits.sections.find( h => h !== null))

        this.arrRhythm = this.rhythmSyllables.length > 0 ? player1.difficulty1.filter( h => h.hit < firstSectionEnd ).sort ( (a,b) => a.hit - b.hit) : [];

    }
    
    onAppIsReady ()
    {
    
    }


    onEnd ()
    {
        super.onEnd();

        if (this.mode === "game")
        {


            if (this.stopMode >= 1)
            {
                let e = new Event("section-complete");
                e.section = this.stopMode + 1;
                window.dispatchEvent(e);

                return;
            }
            else
            {
                let e = new Event("game-complete");
                e.section = 5;
                window.dispatchEvent(e);

                return;
            }
        }
    }

    onDrawComplete ()
    {

        if (this.mode === "game")
        {
            let maskTexture = Library.getTextureFromAtlas("ui", "gradient.png")
            let maskWhiteTexture = Library.getTextureFromAtlas("ui", "white.png")

            let spContainerMask = new PIXI.Container();

            let spMask = new PIXI.Sprite(maskTexture);
            spMask.width = 300;
            spMask.height = this.currentHeight;
            spContainerMask.addChild(spMask);


            let spWhiteMask = new PIXI.Sprite(maskWhiteTexture);
            spWhiteMask.width = this.currentWidth;
            spWhiteMask.height = this.currentHeight;
            spWhiteMask.x = 300;
            spContainerMask.addChild(spWhiteMask);


            //this.spContainerMask.addChild(this.spWhiteMask);


            let texture = this.app.renderer.generateTexture(spContainerMask, PIXI.SCALE_MODES.NEAREST, 1);

            let containerMask = new PIXI.Sprite(texture);

            this.container.addChild(containerMask);


            this.spScrollable.mask = containerMask;
            this.spScrollable.x = 300;

            let currentPos = 0;

            for (let i = 0 ; i < this.stopMode; i++)
            {
                currentPos = this.nextSectionStop;
                this.nextSectionStop = this.playersHits.sections[i];
            }

            if (this.stopMode > 1)
            {
                if (this.karaoke)
                {
                    this.karaoke.visible = false;
                }
            }
            else if (!this.karaokeOver)
            {
                _.forEach(this.arrFadeOutables, (sp) => sp.alpha = 0);
            }

            currentPos = Math.max(currentPos - 1500, 0);
            let posX = this.getPositionFromHit(currentPos / 1000);

            let iAudioPos = currentPos / 1000;
            this.audio.volume(1);
            this.audio.seek(iAudioPos);


            this.moveScrollToSeekPos();
        }
        else
        {
            this.spScrollable.x = 100;


        }


        return;

        let last = 0;
        let color = [0x990000, 0x009900, 0x000099];
        let cpt = 0;

        for (let i = 0; i < 60; i+=5)
        {
            let sp = new PIXI.Graphics();

            sp.beginFill(color[cpt % 3], 1)
            sp.drawRect(0,0, 1, this.currentHeight);
            sp.endFill();

            let posX = this.getPositionFromHit(i * 1000);
            sp.x = posX;

            let diff = posX - last;


            last = posX;
            this.spScrollable.addChild(sp);

            cpt++;
        }

    }

    resetSong ()
    {
        
        console.log("checkIfReady", this.state)
        
        if (!this.state.load)
        {
            this.loadAssets();
        }
        
        return;
        
        this.audio.seek(0);
        this.isPlaying = false;
    }


    onSectionComplete ()
    {

        this.audio.off("fade", this.fctFade);
        this.audio.pause();
        let e = new Event("section-complete");
        e.section = this.stopMode + 1;
        window.dispatchEvent(e);
    }

    whilePlaying (first = false)
    {
        if (this.mode === "preview")
        {
            super.whilePlaying(first);
            return;
        }

        if (first || this.isPlaying)
            requestAnimationFrame(this.fctWhilePlaying);




        let duration = this.audio.duration();
        let current = this.audio.seek();

        let milliCurrent = Math.round(current * 1000)


        if (this.isPlaying && this.stopMode >= 1 && (milliCurrent >= this.nextSectionStop))
        {
            //this.pause();
            this.isPlaying = false;

            this.fctFade = this.onSectionComplete.bind(this);
            this.audio.on("fade", this.fctFade);
            this.audio.fade(1,0, 2000)



            return;
        }

        let pos = this.moveScrollToSeekPos();
        // this.spBackgroundPlayHead.width = this.spPlayHead.x;


        let arrPlayers = [this.player1, this.player2, this.player3, this.rhythm];

        let scaleDistance = 50 * this.baseScale;

        for (let i = 0; i < arrPlayers.length; i++)
        {
            let player  = arrPlayers[i];


            if (player && player.children)
            {
                for (let j = 0; j < player.children.length; j++)
                {
                    let hit = player.children[j];

                    let x = hit.x;

                    let diff = Math.abs(x - pos);

                    if (diff < scaleDistance)
                    {
                        let ratio = (diff / scaleDistance)
                        let scale = ((1 - ratio) * 0.75) * this.baseScale;

                        console.log(hit.hitSize);
                        hit.scale.x = hit.scale.y = (this.baseScale + scale) * hit.hitSize;
                    }
                    else if (hit.scale.x !== this.baseScale)
                    {
                        hit.scale.x = hit.scale.y = this.baseScale * hit.hitSize;
                    }


                }
            }
            else
            {

            }
        }

        if (!this.karaokeOver)
        {
            let timingCurrent = (current * 1000) >> 0;
            let currentSyllable = this.arrSyllables.find(s => timingCurrent > s.timing.hit)
            let currentSyllableIndex = this.arrSyllables.findIndex(s => timingCurrent > s.timing.hit)


            if (currentSyllable)
            {
                if (this.currentSyllableHighlighted && (this.currentSyllableHighlighted.timing.hit !== currentSyllable.timing.hit))
                {
                    this.currentSyllableHighlighted.container.tint = 0xFFFFFF;
                    this.currentSyllableHighlighted.container.alpha = 1
                    this.currentSyllableHighlighted.container.filters = [];
                    this.currentSyllableHighlighted = null;


                }

                if (currentSyllable && this.currentSyllableHighlighted === null)
                {
                    this.currentSyllableHighlighted = currentSyllable;
                    this.currentSyllableHighlighted.container.tint = 0xfeaa0a;
                    this.currentSyllableHighlighted.container.filters = [new DropShadowFilter({distance:2, blur:1, quality: 5, alpha:0.75})/*, new GlowFilter({color:0xFFFFFF, quality:1,outerStrength:2, distance: 5})*/];

                }
            }

            if (currentSyllableIndex === 0)
            {
                if (this.karaoke)
                {
                    let ptWorld = this.spScrollable.toLocal({x: 0, y: 0}, this.karaoke);
                    this.karaoke.x = ptWorld.x;
                    this.karaoke.y = ptWorld.y;
                    this.spScrollable.addChild(this.karaoke);
                }

                this.karaokeOver = true;

                let tween = {alpha: 1, duration: 1};
                _.forEach(this.arrFadeOutables, (sp) =>
                {
                    gsap.to(sp, tween);

                });


            }


        }

    }

    drawUI ()
    {
        this.firstSeparatorX = 100;

        super.drawUI();
    }

    getSongWidth ()
    {
        let width;

        if (this.mode === "game")
        {
            width = (this.currentWidth * 12) - (this.startOffsetX * 2) ;
        }
        else
        {
            width = this.currentWidth - (100) ;
        }




        return width;
    }
}