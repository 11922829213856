import * as PIXI from "pixi.js";
import RoundBtn from "./RoundBtn.js";

export default class SelectPlayer extends PIXI.Container
{
    constructor (options)
    {
        super();

        this.options = options;

        this.build();
    }

    async build ()
    {
        this.labelStyle = new PIXI.TextStyle({fontFamily: "Poppins",fontSize: 14});
        this.label = new PIXI.Text(this.options.label, this.labelStyle);

        this.addChild(this.label);

        let metrics = PIXI.TextMetrics.measureText(this.options.label, this.labelStyle);

        let texture = this.options.texture;

        let labels = this.options.labels ?? [];
        let values = this.options.values ?? [];
        
        if (labels.length === 0)
        {
            labels = Array.from(Array(3), (r,i) => (i+1).toString());
        }
    
        if (values.length === 0)
        {
            values = Array.from(Array(3), (r,i) => (i+1));
        }
        
        this.btns = [];
        
        for (let i = 0; i < labels.length; i++)
        {
            let btn = new RoundBtn({texture, label: labels[i]});
            btn.x = i * 75;
            btn.y = metrics.height + 10;
    
            this.addChild(btn);
    
            btn.on("pointerup", () => this.selectPlayer(btn, values[i]));
    
            this.btns.push(btn);
        }
        

        // this.btnPlayer2 = new RoundBtn({texture, label:2});
        // this.btnPlayer2.x = 75;
        // this.btnPlayer2.y = metrics.height + 10;
        //
        // this.addChild(this.btnPlayer2);
        //
        // this.btnPlayer3 = new RoundBtn({texture, label: 3});
        // this.btnPlayer3.x = 150;
        // this.btnPlayer3.y = metrics.height + 10;
        //
        // this.addChild(this.btnPlayer3);



        this.selectPlayer (this.btns[this.options.selected ?? 0]);

        // this.fctSelected =  this.selectPlayer.bind(this);
        // this.btnPlayer1.on("pointerup", () => this.selectPlayer(this.btnPlayer1));
        // this.btnPlayer2.on("pointerup", () => this.selectPlayer(this.btnPlayer2));
        // this.btnPlayer3.on("pointerup", () => this.selectPlayer(this.btnPlayer3));
    }

    setButtonLabelTexture (index, texture)
    {
        this.btns[index].setLabelTexture(texture)
    }
    selectPlayer (player, value)
    {
        if (this.selected === player)
            return;

        if (this.selected)
            this.selected.unselected();

        this.selected = player;
        this.selected.selected();;


        this.emit("change", value)


    }
}