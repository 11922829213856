import * as PIXI from "pixi.js";

export default class RoundBtn extends PIXI.Container
{
    constructor (options)
    {
        super();

        this.options = options;

        if (!this.options.texture)
        {

        }

        this.build();
    }

    build ()
    {
        this.bg = new PIXI.Sprite(this.options.texture);
        this.bg.width = 50;
        this.bg.height = 50;

        this.addChild(this.bg);

        if(this.options.label instanceof PIXI.Texture)
        {
            this.labelTexture = new PIXI.Sprite(this.options.label);
            //this.labelTexture.anchor = {x: 0.5, y: 0.5};
            this.labelTexture.width = 25;
            this.labelTexture.height = 25;
            this.labelTexture.x = 12.5;
            this.labelTexture.y = 12.5;
    
            this.addChild(this.labelTexture);
        }
        else
        {
            this.labelStyle = new PIXI.TextStyle({fontFamily: "Poppins", fontSize: 22});
            this.label = new PIXI.Text(this.options.label, this.labelStyle);
    
            this.label.x = this.bg.width / 2;
            this.label.y = this.bg.height / 2;
            this.label.anchor = {x: 0.5, y: 0.5};
    
            this.addChild(this.label);
        }

        this.interactive = true;
        this.buttonMode = true;

    }

    setLabelTexture (texture)
    {
        this.labelTexture.texture = texture;
    }
    selected ()
    {
        this.bg.tint = 0x990000;
    }

    unselected ()
    {
        this.bg.tint = 0xFFFFFF;
    }

    get text ()
    {
        return this.label.text;
    }
}