import Base from "./Base.js";
import Library from "../Library.js";
import WaveSurfer from "wavesurfer";
import * as PIXI from "pixi.js";
import Display from "./utils/Display.js";
import Slider from "./ui/Slider.js";
import RoundBtn from "./ui/RoundBtn.js";
import SelectPlayer from "./ui/SelectPlayer.js";
import axios from "axios";
import RespectTiny from "../responsive/RespectTiny.js";

export default class Editor extends Base
{
    constructor(selector, id)
    {
        super(id);
        this.selector = selector;
        
        //this.buildApp();
        this.loadAssets();
        
    }
    
    buildApp ()
    {
        this.removeApp();

        this.canvas = document.getElementById("editorCanvas");

        this.fctGetAvailableSizeForCanvas = this.getAvailableSizeForCanvas.bind(this)
        this.optionsMeasurable = {display: this.canvas, closure: this.fctGetAvailableSizeForCanvas};
        Display.addMeasurable(this.optionsMeasurable);

        let {width, height} = Display.getSize();

        this.app = new PIXI.Application
        (
            {
                view: this.canvas,
                resolution:  1,//window.devicePixelRatio,
                transparent: true,
                backgroundColor: 0xf0b1e5, //0x191036,

                width,
                height,
                antialias: true

            }
        );

        this.container = new PIXI.Container();

        this.app.stage.addChild(this.container);

        RespectTiny.addResponsive(
            {
                responsiveDisplay: null,
                responsive: {},
                callback: (r) => this.onResize(r)
            });

        this.appIsBuilt = true;

        this.draw();

        // setInterval(() => this.drawRandom(), 1000)
    }

    init ()
    {
        super.init();

        this.playback = 1;
        this.difficulty = 1;
        this.currentPlayer = 1;
        this.hitSize = 1;
        this.lastRemove = 0;
        this.fctChangePlayer = this.onChangePlayer.bind(this);
        this.fctChangeDifficulty = this.onChangeDifficulty.bind(this);
        this.fctChangeSize = this.onChangeSize.bind(this);
        this.fctChangeMode = this.onChangeMode.bind(this);
        this.fctChangeShape = this.onChangeShape.bind(this);
        this.fctSave = this.onSave.bind(this);
        this.fctChangeBarCount = this.onChangeBarCount.bind(this);

        this.fctOnKeyUpMove = this.onKeyUp_move.bind(this);
        this.fctAddSection = this.addSection.bind(this);

        this.mode = "add";
    }

    async onAssetsLoaded ()
    {
        let response = await axios.get("/api/mp3/" + window.history_id);
        
        let mp3 = response.data.file;
        
        this.mp3 = mp3;
    
        this.loader.loadAudios({audios: [{
                "key": "audios",
                "type": "audio/mp3",
                "source": mp3
            }]});
    
        this.loader.once("load-complete", () => this.prepareEditor());
        console.log("onAssetsLoaded", mp3);
        
    }
    
    prepareEditor ()
    {
        this.assetsAreLoaded = true;
    
        this.audio = Library.getAudio("audio-audios");
        let historyId = window.history_id;
        this.playersHits = this.adjustHits(Library.getData("song-" + historyId));
    
        this.onCanvasReady(2, 2);
        this.draw();
        //this.addEvents();
    
        this.surfer = WaveSurfer.create(
            {
                container:"#surfer",
                progressColor: '#FFFFFF',
            
                cursorColor: 'transparent'
            }
        )
    
    
        //this.surfer.load("/_/audios/this-is-not-dvorak.mp3");
        this.surfer.load("/_" + this.mp3);
    
        this.surfer.on("ready", () => setTimeout(() => this.displayWaveform(), 1000))
    }

    onCanvasReady (numberOfTeams = 2, difficulty = 2)
    {
        super.onCanvasReady(numberOfTeams, 1)
    }


    displayWaveform ()
    {
        let canvases = document.querySelectorAll("canvas");


        let img = this.convertCanvasToImage(canvases[1]);

        const base = new PIXI.BaseTexture(img);
        const texture = new PIXI.Texture(base);
        let spWaveform = new PIXI.Sprite(texture);

        let {width, height} = Display.getSize();

        spWaveform.width = this.getSongWidth();
        spWaveform.height = height / 2;
        spWaveform.anchor.y = 0.5;
        spWaveform.x = 0;
        spWaveform.y = height / 1.5;

        spWaveform.alpha = 0.5;
        spWaveform.tint = 0;

        this.spWaveform = spWaveform;

        this.waitForScrollable();


        //document.body.appendChild(img);

        // canvases.forEach(e =>
        // {
        //     if (e.getAttribute("id") !== "editorCanvas" )
        //         e.parentNode.removeChild(e);
        // });
        //
        // let surfer = document.querySelector("#surfer");
        // surfer.parentNode.removeChild(surfer);
    }

    waitForScrollable ()
    {
        if (!this.spScrollable)
        {
            setTimeout(() => this.waitForScrollable(), 50);
            return;
        }

        this.spScrollable.addChildAt(this.spWaveform, 0);
    }

    onDrawComplete ()
    {
        this.spBackground.interactive = true;
        this.spBackground.on("pointerup", (e) => this.addHit(e));

        this.moveScrollToSeekPos();
    }

    addHitOnPlayhead (e)
    {
        let code = e.key;

        if (code === "h")
        {
            let e = {data:{
                global: {
                    x: this.spPlayHead.x,
                    y: this.currentHeight / 2
                }
                }}
            this.addHit(e)
        }
    }

    getSongWidth ()
    {
        let width = (this.currentWidth / 0.5) - (this.startOffsetX * 2) ;



        return width;
    }

    addHit(e)
    {
        if (this.mode !== "add")
            return;
    
        
        let pos = this.container.toLocal({x:e.data.global.x, y:e.data.global.y});
        let posScroll = this.spScrollable.toLocal({x:e.data.global.x, y:e.data.global.y});


        let {width, height} = Display.getSize();

        if (pos.x < this.firstSeparatorX)
            return;



        if ((pos.y < height / 3) || (pos.y > (height * (2/3))))
            return;


        let hit = this.getHitFromPosition(posScroll.x);

        let strPlayer = "player" + this.currentPlayer;
        let strDifficulty = "difficulty" + this.difficulty;

        if (!this.playersHits[strPlayer])
        {
            this.playersHits[strPlayer] = {};
        }

        if (!Array.isArray(this.playersHits[strPlayer][strDifficulty]))
            this.playersHits[strPlayer][strDifficulty] = [];


        let list = this.playersHits[strPlayer][strDifficulty];

        let exist = list.map( i => i.hit ).includes(hit);

        let size = this.hitSize;
        let shape = this.shape;
    
        let isShift = e?.data?.originalEvent?.shiftKey ?? false;
        
    
        if (isShift)
        {
            if (!this.posBeginLine)
            {
                this.posBeginLine = posScroll.x;
            }
            else
            {
                let hitStart = this.getHitFromPosition(this.posBeginLine);
                let hitEnd = hit;
                
                let diff = hitEnd - hitStart;
                console.log(diff + " = " + hitEnd + " - " + hitStart);
                
                let offset = this.getHitFromPosition(10);
                
                let count = Math.round(Math.abs(diff / offset));
                let dir = Math.abs(diff) / diff;
               console.log("count", count);
               console.log("dir", dir);
    
                let hitIntermediary = hitStart;
                
                
                console.log(hitStart, "start")
                for (let i = 0; i < count -1; i++)
                {
                    hitIntermediary += (offset * dir);
                    
                    this.playersHits[strPlayer][strDifficulty].push({hit: hitIntermediary, size, shape});
                    
                    console.log(hitIntermediary)
                }
                
                console.log(hitEnd, "end");
                
                this.posBeginLine = undefined;
            }
        }
        
        if (!exist)
        {
            this.playersHits[strPlayer][strDifficulty].push({hit, size, shape});


            this.drawMusicalGuideForPlayers(true, this.playersHits)
        }


    }


    addSection (e)
    {
        let hit = this.drawOneSeparator({x:e.clientX, y:e.clientY});

        if (hit === null || hit === undefined)
            return;

        if (!this.playersHits.sections)
            this.playersHits.sections = [];

        this.playersHits.sections.push(hit);

    }

    drawSeparator ()
    {
        super.drawSeparator();

        let sections = (this.playersHits.sections ?? []).filter ( h => h !== null);


        for (let i = 0; i < sections.length; i++)
        {
            let x = this.getPositionFromHit(sections[i])
            let {width, height} = Display.getSize();
            this.drawOneSeparator({x: x, y: height / 2}, false)
        }
    }

    drawOneSeparator (mousePos, bCheckPos = true)
    {
        let now = new Date().getTime();
        let elapsed = now - this.lastRemove;

        if (elapsed < 1000)
            return;

        let pos = this.container.toLocal(mousePos);
        let posScroll = this.spScrollable.toLocal(mousePos);

        let {width, height} = Display.getSize();

        if (bCheckPos)
        {
            if (pos.x < this.firstSeparatorX)
                return;

            if (pos.x > (width - this.firstSeparatorX))
                return;


            if ((pos.y < height / 3))
                return;
        }


        let hit = this.getHitFromPosition(posScroll.x);
        let x = this.getPositionFromHit(hit)

        let line = this.getLine();

        line.x = x;

        this.spScrollable.addChild(line);


        line.hit = hit;
        line.buttonMode = line.interactive = true;
        line.fct = (e) => this.removeSeparator(e, line);

        line.on("pointerup", line.fct)
        



        return hit;

    }
    
    createCircle ()
    {
        let gfx = new PIXI.Graphics();
        gfx.beginFill(Math.round(Math.random() * 0xFFFFFF), 1);
        gfx.drawCircle(0,0, 20);
        gfx.endFill();

        return gfx;
    }

    removeSeparator (e, line)
    {
        this.lastRemove = new Date().getTime();

        this.playersHits.sections = this.playersHits.sections.filter( s => s !== line.hit);

        line.off("pointerup", line.fct);
        line.fct = null;
        line.parent.removeChild(line);
    }

    getLine ()
    {
        let {width, height} = Display.getSize();

        let gfx = new PIXI.Graphics();
        gfx.beginFill(0,1);
        gfx.drawRect(-1,0, 3, height);
        gfx.endFill();

        return gfx;
    }

    async drawUI ()
    {
        super.drawUI();

        let {width, height} = Display.getSize();

        this.spUI = new PIXI.Container();

        this.slideSpeed = new Slider({label:"Playback", value: 1, min:0.25, max:2});
        this.spUI.addChild(this.slideSpeed);

        this.slideSpeed.x = 20;
        this.slideSpeed.y = 20;

        this.slideSpeed.on("change", (e) => this.changePlayback(e))



        this.slideAlpha = new Slider({label:"Waveform alpha", value: 0.5, min:0, max:1});
        this.spUI.addChild(this.slideAlpha);



        this.slideAlpha.x = this.slideSpeed.x + this.slideSpeed.width + 40;
        this.slideAlpha.y = 20;

        this.slideAlpha.on("change", (e) => this.spWaveform.alpha = e);


        let texture = await PIXI.Texture.from("/_/images/bg-btn-purple.png");
        this.selectPlayer = new SelectPlayer({label:"Joueur", texture});
        this.selectPlayer.x = 500;
        this.selectPlayer.y = 20;
        this.selectPlayer.on("change", this.fctChangePlayer)

        this.spUI.addChild(this.selectPlayer);

        this.difficulty = 1;
        texture = await PIXI.Texture.from("/_/images/bg-btn-red.png");
        this.selectDifficulty = new SelectPlayer({label:"Difficulté", texture});
        this.selectDifficulty.x = this.selectPlayer.x + this.selectPlayer.width + 50;
        this.selectDifficulty.y = 20;
        this.selectDifficulty.on("change", this.fctChangeDifficulty)

        this.spUI.addChild(this.selectDifficulty);
    
        texture = await PIXI.Texture.from("/_/images/bg-btn-purple.png");
        this.selectSize = new SelectPlayer({label:"Taille", texture,  labels:["p","m","g"], values:[0.5, 1, 2], selected: this.hitSize});
        this.selectSize.x = this.selectDifficulty.x + this.selectDifficulty.width + 50;
        this.selectSize.y = 20;
        this.selectSize.on("change", this.fctChangeSize)
    
        this.spUI.addChild(this.selectSize);
    
    
        let textureCircle = Library.getTextureFromAtlas("ui", "circle.png");
        let textureSquare = Library.getTextureFromAtlas("ui", "square.png");
        let textureTriangle = Library.getTextureFromAtlas("ui", "triangle.png");
        let textureFeuilleCircle = Library.getTextureFromAtlas("ui", "feuille_rose.png");
        let textureFeuilleSquare = Library.getTextureFromAtlas("ui", "feuille_bleu.png");
        let textureFeuilleTriangle = Library.getTextureFromAtlas("ui", "feuille_jaune.png");
        
        this.shape = 0;
        texture = await PIXI.Texture.from("/_/images/bg-btn-red.png");
        this.selectShape = new SelectPlayer({label:"Forme", texture, labels:[textureSquare, textureFeuilleSquare], values:[0, 1]});
        this.selectShape.x = this.selectSize.x + this.selectSize.width + 50;
        this.selectShape.y = 20;
        this.selectShape.on("change", this.fctChangeShape)
    
        this.spUI.addChild(this.selectShape);



        this.btnRewind = new RoundBtn({label: "<<", texture});
        this.btnRewind.x = this.currentWidth - this.btnRewind.width - 10;
        this.btnRewind.y = this.currentHeight / 2;
        this.btnRewind.on("pointerup", () => this.rewindAudio());
        this.spUI.addChild(this.btnRewind);


        this.btnFF = new RoundBtn({label: ">>", texture});
        this.btnFF.x = this.currentWidth - this.btnRewind.width - 10;
        this.btnFF.y = this.currentHeight / 2 + this.btnRewind.height + 10;
        this.btnFF.on("pointerup", () => this.fastForwardAudio());
        this.spUI.addChild(this.btnFF);


        if (this.spWaveform)
            this.spUI.addChild(this.spWaveform);


        this.container.addChild(this.spUI);

        this.selMode = document.querySelector("#selMode");

        this.selMode.addEventListener("change", this.fctChangeMode);


        this.btnSave = document.querySelector("#btnSave");

        this.btnSave.addEventListener("pointerup", this.fctSave);


        this.txtBarsCount = document.querySelector("#countBars");
        this.txtBarsCount.value = this.playersHits.bars.count;

        this.txtBarsCount.addEventListener("change", this.fctChangeBarCount);


        document.addEventListener ("keydown", (e) => this.addHitOnPlayhead(e));
    }

    onChangeMode (e)
    {
        if (this.mode === "delete")
        {
            this.stopDeleteMode();
        }
        else if (this.mode === "move")
        {
            this.stopMoveMode();
        }
        else if (this.mode === "section")
        {
            this.stopSectionMode();
        }

        this.mode = e.target.value;

        if (this.mode === "delete")
        {
            this.startDeleteMode();
        }
        if (this.mode === "move")
        {
            this.startMoveMode();
        }
        else if (this.mode === "section")
        {
            this.startSectionMode();
        }


    }

    changePlayback (value)
    {

        this.playback = value;

        if (this.audio.playing())
        {
            this.pause();
            this.play();
        }
    }

    rewindAudio ()
    {
        let current = this.audio.seek();

        current -= (3 * this.playback);

        if (current < 0)
            current = 0;

       this.audio.seek(current);

       this.moveScrollToSeekPos();

    }

    fastForwardAudio()
    {
        let current = this.audio.seek();
        let duration = this.audio.duration();

        current += 10;

        if (current > duration - 10)
            return;

        this.audio.seek(current);

        this.moveScrollToSeekPos();

    }

    convertCanvasToImage(canvas)
    {
        let image = new Image();
        image.src = canvas.toDataURL();
        return image;
    }

    play ()
    {
        let d = this.audio.duration();
        this.audio.rate (this.playback)
        this.songId = this.audio.play();
        this.isPlaying = true;
    }



    distributePlayerLane ()
    {
        let {width, height} = Display.getSize();

        this.player1.y = height / 2;
        this.player2.y = height / 2;
        this.player3.y = height / 2;;

        this.player1.alpha = this.currentPlayer == 1 ? 1 : 0.5;
        this.player2.alpha = this.currentPlayer == 2 ? 1 : 0.5;
        this.player3.alpha = this.currentPlayer == 3 ? 1 : 0.5;


        if (this.currentPlayer == 1)
        {
            this.player2.y -= 100;
            this.player3.y += 100;
        }
        else if (this.currentPlayer == 2)
        {
            this.player1.y -= 100;
            this.player3.y += 100;
        }
        else if (this.currentPlayer == 3)
        {
            this.player1.y -= 100;
            this.player2.y += 100;
        }
    }

    // drawMusicalGuide (texture, hits)
    // {
    //     let guide = new PIXI.Container();
    //
    //     let iBar = Math.round(44 * (1 + this.difficulty));
    //
    //     let iX = this.oneBeatWidth * 2;
    //
    //     for (let i = 0; i < iBar; i++)
    //     {
    //         let guideItem = new PIXI.Sprite(texture);
    //         guideItem.anchor = {x:0.5, y: 0.5};
    //         guideItem.width = this.oneBeatWidth / 5;
    //         guideItem.scale.y  = guideItem.scale.x;
    //
    //         if (iX < (this.currentWidth - this.oneBeatWidth / 2))
    //         {
    //             guideItem.x = iX;
    //
    //             iX += this.getGuideOffset();
    //             guide.addChild(guideItem);
    //         }
    //     }
    //
    //     return guide;
    // }


    onChangePlayer (player)
    {

        this.currentPlayer = player;
    
        let textureCircle = Library.getTextureFromAtlas("ui", "circle.png");
        let textureSquare = Library.getTextureFromAtlas("ui", "square.png");
        let textureTriangle = Library.getTextureFromAtlas("ui", "triangle.png");
    
        let textureFeuilleCircle = Library.getTextureFromAtlas("ui", "feuille_rose.png");
        let textureFeuilleSquare = Library.getTextureFromAtlas("ui", "feuille_bleu.png");
        let textureFeuilleTriangle = Library.getTextureFromAtlas("ui", "feuille_jaune.png");
        
        let shapes = [textureSquare,textureTriangle , textureCircle];
        let shapesFeuille = [textureFeuilleSquare,textureFeuilleTriangle , textureFeuilleCircle];
        
        this.selectShape.setButtonLabelTexture(0, shapes[player - 1]);
        this.selectShape.setButtonLabelTexture(1, shapesFeuille[player - 1]);

        this.distributePlayerLane();
    }

    onChangeDifficulty (difficulty)
    {
        this.difficulty = difficulty;

        this.drawMusicalGuideForPlayers (true, this.playersHits)

    }
    
    onChangeSize (size)
    {
        this.hitSize = size;
    }
    
    onChangeShape (shape)
    {
        this.shape = shape;
    }

    stopMoveMode ()
    {
        if (this.spSelected)
        {
            this.spSelected.alpha = 1;
        }

        let p = [this.player1, this.player2, this.player3];

        for (let i = 0; i < p.length; i++)
        {
            let player = p[i];
            let children = player.children;

            for (let j = 0; j < children.length; j++)
            {
                let sp = children[j];

                sp.interactive = false;
                sp.buttonMode = false;

                sp.off("pointerup", sp.fct)
            }
        }

        document.removeEventListener("keyup", this.fctOnKeyUpMove);
    }

    stopDeleteMode ()
    {
        let p = [this.player1, this.player2, this.player3];

        for (let i = 0; i < p.length; i++)
        {
            let player = p[i];
            let children = player.children;

            for (let j = 0; j < children.length; j++)
            {
                let sp = children[j];

                sp.interactive = false;
                sp.buttonMode = false;

                sp.off("pointerup", sp.fct)
            }
        }
    }

    startMoveMode ()
    {
        let p = [this.player1, this.player2, this.player3];

        for (let i = 0; i < p.length; i++)
        {
            let player = p[i];
            let children = player.children;

            for (let j = 0; j < children.length; j++)
            {
                let sp = children[j];

                sp.interactive = true;
                sp.buttonMode = true;

                sp.fct = () => this.selectHit(sp, player, i + 1);

                sp.on("pointerup", sp.fct)

            }
        }

        document.addEventListener("keyup", this.fctOnKeyUpMove);
    }

    startDeleteMode ()
    {
        let p = [this.player1, this.player2, this.player3];

        for (let i = 0; i < p.length; i++)
        {
            let player = p[i];
            let children = player.children;

            for (let j = 0; j < children.length; j++)
            {
                let sp = children[j];

                sp.interactive = true;
                sp.buttonMode = true;

                sp.fct = () => this.removeFromList(sp, player, i + 1);

                sp.on("pointerup", sp.fct)

            }
        }
    }

    startSectionMode ()
    {
        document.addEventListener("mouseup", this.fctAddSection);


    }

    stopSectionMode ()
    {
        document.removeEventListener("mouseup", this.fctAddSection);


    }

    toggleLineSection (line)
    {
        line.alpha = line.alpha === 1 ? 0.25 : 1;

        let index = line.index;

        if (line.alpha === 1)
        {
            this.playersHits.bars.sections.push(line.index);
            this.playersHits.bars.sections.sort( (a,b) => a-b);
        }
        else
        {
            let sections = this.playersHits.bars.sections.filter( s => s !== index);
            this.playersHits.bars.sections = sections;
        }

    }

    selectHit (sp, container, playerIndex)
    {
        if (this.spSelected)
        {
            this.spSelected.alpha = 1;
        }

        this.spSelected = sp;
        this.spSelected.alpha = 0.5;

    }

    onKeyUp_move(e)
    {
        if (!this.spSelected)
            return;


        let code = e.code;
        let offset = 100;


        if (e.shiftKey)
            offset = 10;

        if (code === "ArrowLeft")
        {
            offset *= -1;
        }
        else if (code === "ArrowRight")
        {
            offset *= 1;
        }
        else if (code === "KeyP")
        {
            this.snapSelectedToOtherLane();
            return;
        }
        else
        {
            return;
        }

        ;
        this.recalculatePosition(this.spSelected, this.spSelected.hit + offset)

    }

    snapSelectedToOtherLane ()
    {
        if (!this.spSelected)
            return;

        let currentHit = this.spSelected.hit;

        let listPlayer1 = this.playersHits["player1"]["difficulty" + this.difficulty];
        let listPlayer2 = this.playersHits["player2"]["difficulty" + this.difficulty];
        let listPlayer3 = this.playersHits["player3"]["difficulty" + this.difficulty];

        let fctReduce = (a,h) =>
        {
            let diff = (h.hit - currentHit);

            if (Math.abs(diff) < Math.abs(a) && diff !== 0)
            {
                return diff;
            }

            return a;
        };

        let closest1 = listPlayer1.reduce( fctReduce, 1000000);
        let closest2 = listPlayer2.reduce( fctReduce, 1000000);
        let closest3 = listPlayer3.reduce( fctReduce, 1000000);

        let arrClosest = [
            {val: closest1, abs: Math.abs(closest1)},
            {val: closest2, abs: Math.abs(closest2)},
            {val: closest3, abs: Math.abs(closest3)},
            ];

        arrClosest.sort ((a,b ) => a.abs - b.abs);


        let offset = arrClosest[0].val;

        if (Math.abs(offset) > 1000)
        {
            return;
        }

        this.recalculatePosition(this.spSelected, this.spSelected.hit + offset)

    }
    recalculatePosition (sp, newVal)
    {
        let oldVal = sp.hit;

        let index = this.playersHits["player" + this.currentPlayer]["difficulty" + this.difficulty].findIndex( h => h.hit == oldVal);

        this.playersHits["player" + this.currentPlayer]["difficulty" + this.difficulty][index].hit = newVal;
        this.playersHits["player" + this.currentPlayer]["difficulty" + this.difficulty] = this.playersHits["player" + this.currentPlayer]["difficulty" + this.difficulty].sort( (a,b) => a.hit-b.hit);
        sp.hit = newVal;

        sp.x =  this.getPositionFromHit(newVal);
    }
    removeFromList (sp, container, playerIndex)
    {
        let hit = sp.hit;
        container.removeChild(sp);

        let list = this.playersHits["player" + playerIndex]["difficulty" + this.difficulty];

        console.log("allo")
        list = list.filter( h => h.hit !== hit);

        this.playersHits["player" + playerIndex]["difficulty" + this.difficulty] = list;

    }


    async onSave ()
    {
        let id = document.querySelector("[data-id]").getAttribute("data-id");
        let response = await axios.post("/api/song/save/" + id, this.playersHits);

    }

    onChangeBarCount (e)
    {

        let iCount = parseInt(e.target.value);

        if (iCount === 0 || iCount === parseInt(this.playersHits.bars.count))
            return;

        this.playersHits.bars.count = parseInt(e.target.value);

        this.reset();
    }


    reset ()
    {
        super.reset();

        this.container.addChild(this.spUI);
    }


    moveScrollToSeekPos ()
    {
        let duration = this.audio.duration();
        let current = this.audio.seek();

        let ratio = current / duration;

        let pos = this.getSongWidth() * ratio;
        this.spPlayHead.x = 300; //;

        this.spScrollable.x = this.spPlayHead.x-pos;

        return pos;
    }

    whilePlaying (first = false)
    {
        if (first || this.isPlaying)
            requestAnimationFrame(this.fctWhilePlaying);



        let duration = this.audio.duration();
        let current = this.audio.seek();

        let milliCurrent = Math.round(current * 1000)




        let pos = this.moveScrollToSeekPos();
        // this.spBackgroundPlayHead.width = this.spPlayHead.x;





    }



}