import PIXILoader from "./PIXILoader.js";
import axios from "axios";
import Library from "../Library.js";
import WebFont from "webfontloader";
import EventEmitter from "eventemitter3";

export default class Loader extends EventEmitter
{
    constructor (_strAssetsList, strBasePath)
    {
        super();
        this.strBasePath = strBasePath;
        this.fctLoadNextGroup = () => this.loadNextGroup();
        this.fctOnPixiLoaderGroupLoaded = (resources) => this.onPixiLoadedComplete( resources);
        this.pixiLoader = new PIXILoader();
        this.pixiLoader.on("group-loaded", this.fctOnPixiLoaderGroupLoaded );



        this.fctLoader = {
            "images": (group) => this.loadPixiAssets(group),
            "atlas": (group) => this.loadPixiAssets(group),
            "fonts": (group) => this.loadFonts(group),
            "audios": (group) => this.loadAudios(group),
            "datas": (group) => this.loadDatas(group),
        }

        this.loadAssetsList(_strAssetsList);
    }

    static getLang ()
    {
        return document.documentElement.lang;
    }

    async loadAssetsList (_strAssetsList)
    {
        _strAssetsList = Loader.addVersionToURL (_strAssetsList);
        let response  = await axios.get(this.strBasePath + _strAssetsList);


        this.arrAssetsGroups = response.data;

        this.loadNextGroup();


    }

    loadNextGroup ()
    {
        if (this.arrAssetsGroups.length === 0)
        {
            this.emit("load-complete");
            return;
        }


        let group = this.arrAssetsGroups.shift();

        let strType = group.type;

        if (this.fctLoader[strType])
        {
            this.fctLoader[strType](group);
        }
        else
        {
            this.loadNextGroup();
        }
    }


    async loadAudios (group)
    {
        let arrAssets = group.audios;

        for (let i = 0; i < arrAssets.length; i++)
        {
            let asset = arrAssets[i];

            let sprites = Library.instance.datas["audios_" + asset.key];
            let audio = await this.loadAudio(this.strBasePath + asset.source, sprites);

            Library.addAudio("audio-" + asset.key, audio);

        }

        //since all group will 'await' for a response we can call this here instead of a callback
        this.loadNextGroup();
    }

    async loadAudio (strSrc, sprite = null)
    {
        let strLang = Loader.getLang();

        strSrc = strSrc.replace("{strLang}", strLang);
        strSrc = Loader.addVersionToURL (strSrc);

        let audio = new Howl({
            src: [strSrc],
            preload: false,
            html5: false,
            sprite
        });

        await audio.load();

        return audio;
    }
    async loadDatas (group)
    {
        let strLang = Loader.getLang();
        let arrAssets = group.assets;

        for (let i = 0; i < arrAssets.length; i++)
        {
            let asset = arrAssets[i];
            let url = this.getURLFromAsset(asset, strLang);
            
            let response  = await axios.get(url);

            let data = response.data;
            
            let strKey = this.getKeyFromAsset(asset);
            
            Library.addData(strKey, data);
        }

        //since all group will 'await' for a response we can call this here instead of a callback
        this.loadNextGroup();
    }
    
    getURLFromAsset (asset, strLang)
    {
        let strUrl = asset.url.replace("{strLang}", strLang);
     
        if (asset.params)
        {
            for (let i = 0; i < asset.params.length; i++)
            {
                let p = asset.params[i];
                
                let strExpression = p.expression;
                let value = eval(strExpression);
                let name = p.name;
                
                //let regexp = '/\\' + ":" + name + "/";
                const regexp = new RegExp(":" + name, 'gi')
                strUrl = strUrl.replace(regexp, value);
            }
        }
     
        strUrl = Loader.addVersionToURL (strUrl);
    
        let url = asset.external ? strUrl : this.strBasePath + strUrl;
        
        return url;
    }
    
    getKeyFromAsset (asset)
    {
        let strKey = asset.key;
        
        if (asset.params)
        {
            for (let i = 0; i < asset.params.length; i++)
            {
                let p = asset.params[i];
                
                let strExpression = p.expression;
                let value = eval(strExpression);
                let name = p.name;
                
                //let regexp = '/\\' + ":" + name + "/";
                const regexp = new RegExp(":" + name, 'gi')
                strKey = strKey.replace(regexp, value);
            }
        }
        


        return strKey;
    }

    loadFonts (group)
    {
        group.fonts.loading = this.fctLoadNextGroup;
        WebFont.load(group.fonts)
    }

    loadPixiAssets (group)
    {
        this.pixiLoader.loadGroup(group.assets, this.strBasePath);
        //when this is loaded, onPixiLoadedComplete will be called
    }

    onPixiLoadedComplete (resources)
    {
        this.loadNextGroup();
    }

    static addVersionToURL (url)
    {
        let strQueryParam = url.indexOf("?") > -1 ? "&" : "?";

        url += strQueryParam + "v=" + __COMPILATION_DATE__; //compilation comes from webpack

        return url;
    }


}