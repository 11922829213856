import * as PIXI   from "pixi.js";
import Loader      from "../loaders/Loader.js";
import Display     from "./utils/Display.js";
import RespectTiny from "../responsive/RespectTiny.js";
import Lang        from "./Lang.js";
import Constants   from "../Constants.js";
import Library     from "../Library.js";
import AudioHelper from "./utils/AudioHelper.js";
import Syllable    from "./ui/Syllable.js";
import {gsap}      from "gsap";

export default class Base
{
    constructor (strSelectorCanvas)
    {
        this.init();

        Base.instance = this;

        this.canvasIsReady = false;
        this.assetsAreLoaded = false;
        this.appIsBuilt = false;
        this.isPlaying = false;
        this.isDrawn = false;
        this.fctWhilePlaying = () => this.whilePlaying();

        this.isFullscreen = false;
        this.strSelectorCanvas = strSelectorCanvas;

        this.startOffsetX = 100;
        this.rythmMultiplier = 20;
        this.iPaddingKaraoke = 100;
        this.sectionsEnd = []
        this.arrFadeOutables = [];

        this.state = {load: false, id: 0};
        //this.buildApp(canvas);
        //this.loadAssets();
        
        //this.buildApp();
        
    }

    init ()
    {

    }


    
    buildApp ()
    {
        this.removeApp();

        this.canvas = document.getElementById(this.strSelectorCanvas);



        this.fctGetAvailableSizeForCanvas = this.getAvailableSizeForCanvas.bind(this)
        this.optionsMeasurable = {display: this.canvas, closure: this.fctGetAvailableSizeForCanvas};
        Display.addMeasurable(this.optionsMeasurable);

        let {width, height} = Display.getSize();

        this.app = new PIXI.Application
        (
            {
                view: this.canvas,
                resolution:  1,//window.devicePixelRatio,
                // transparent: true,
                backgroundColor: 0x37746d, //0x191036,
                bg: "#37746d",

                width,
                height,
                antialias: true

            }
        );

        this.container = new PIXI.Container();

        this.app.stage.addChild(this.container);

        RespectTiny.addResponsive(
            {
                responsiveDisplay: null,
                responsive: {},
                callback: (r) => this.onResize(r)
            });

        this.appIsBuilt = true;

        //this.draw();

       // setInterval(() => this.drawRandom(), 1000)
    }

    drawRandom ()
    {
        let gfx = new PIXI.Graphics();
        gfx.beginFill(Math.random() * 0xFFFFFF >> 0, 1);
        gfx.drawRect(Math.random() * 600, Math.random() * 300, 100 + Math.random() * 200, 100 + Math.random() * 100);
        gfx.endFill();
        this.container.addChild(gfx);
    }
    removeApp ()
    {
        if (this.app)
        {
            if (this.optionsMeasurable)
                Display.removeMeasurable(this.optionsMeasurable);

            if (this.app.stage && this.container)
            {
                this.app.stage.removeChild(this.container);
                this.container.destroy();
            }

            this.appIsBuilt = false;

            //TODO remove drawn

            if (this.app.stage)
                this.app.destroy(false);

            this.app = undefined;
        }

        if (this.audio)
        {
            this.audio.pause();
            this.audio.seek(0);
        }
    }


    getAvailableSizeForCanvas (measurable) //measurable will be the actual canvas in this case
    {
        let width = measurable.scrollWidth;
        let height = measurable.scrollHeight;

        //sometime on chrome-mobile the height can be larger than the actual available height
        if (height > window.innerHeight)
        {
           // height = window.innerHeight * 0.99; //google recommends using 99% of available height for some reason
        }

        return {width, height}
    }


    loadAssets ()
    {

        this.loader = new Loader("/data/assets.json", BASE_PATH);
        this.loader.once("load-complete", () => this.onAssetsLoaded());


    }

    onCanvasReady (numberOfTeams = 2, difficulty = 2, stopMode = 0, mode = "", lang = "fr")
    {
        this.canvasIsReady = true;
        this.numbersOfTeams = numberOfTeams;
        this.difficulty = difficulty;
        this.stopMode = stopMode;
        this.lang = lang;

        if (mode !== "")
            this.mode = mode;

        this.buildApp();
    }

    getMp3 ()
    {
        let mp3 = window.app.getMp3();
        
        return mp3;
    }
    
    onAssetsLoaded ()
    {
        
        const mp3 = this.getMp3();
        
        this.loader.loadAudios({audios: [{
            "key": "audios",
            "type": "audio/mp3",
            "source": mp3
        }]});
    
        this.loader.once("load-complete", () => this.prepareApp());
        console.log("onAssetsLoaded", mp3);
    }
    
    prepareApp()
    {
        this.assetsAreLoaded = true;

        this.audio = Library.getAudio("audio-audios");
        this.audio.on("end", () => this.onEnd());
        
        let historyId = window.history_id;
        this.playersHits = this.adjustHits(Library.getData("song-" + historyId));
        

        setTimeout(() => this.draw(), 10);
        //this.addEvents();

    }
    
    adjustHits (list)
    {
        if (typeof list.player1.difficulty1[0] === "number")
        {
            list.player1.difficulty1 = list.player1.difficulty1.map ( hit => ({hit, size: 1, shape:0}));
            list.player1.difficulty2 = list.player1.difficulty2.map ( hit => ({hit, size: 1, shape:0}));
            list.player1.difficulty3 = list.player1.difficulty3.map ( hit => ({hit, size: 1, shape:0}));
    
            list.player2.difficulty1 = list.player2.difficulty1.map ( hit => ({hit, size: 1, shape:0}));
            list.player2.difficulty2 = list.player2.difficulty2.map ( hit => ({hit, size: 1, shape:0}));
            list.player2.difficulty3 = list.player2.difficulty3.map ( hit => ({hit, size: 1, shape:0}));
    
            list.player3.difficulty1 = list.player3.difficulty1.map ( hit => ({hit, size: 1, shape:0}));
            list.player3.difficulty2 = list.player3.difficulty2.map ( hit => ({hit, size: 1, shape:0}));
            list.player3.difficulty3 = list.player3.difficulty3.map ( hit => ({hit, size: 1, shape:0}));
        }
        
        return list;
    }
    
    onEnd ()
    {

        this.togglePlayPause();
    }

    changeNumberOfTeams (numberOfTeams)
    {
        this.numbersOfTeams = numberOfTeams;

        if (!this.canvasIsReady || !this.assetsAreLoaded || !this.appIsBuilt)
            return

        this.drawMusicalGuideForPlayers();
    }

    changeDifficulty (difficulty)
    {
        this.difficulty = difficulty;

        if (!this.canvasIsReady || !this.assetsAreLoaded || !this.appIsBuilt)
            return

        this.drawMusicalGuideForPlayers(true, this.playersHits);
    }


    getSyllablesTiming ()
    {
        
        if (this.playersHits === undefined)
        {
            return [];
        }
        
        let firstSectionEnd = this.getFirstSectionEnd();
        
        if (this.playersHits.rhythm)
        {
    
            let strRhythmSyllable = this.playersHits.rhythm[this.lang].replace(/ +(?= )/g, '')
            let rhythmSyllables = strRhythmSyllable.split("\\n");
            let syllableTiming = this.playersHits.player1["difficulty" + "1"].filter(t => t.hit < firstSectionEnd);
    
            let iCpt = 0;
    
            rhythmSyllables = rhythmSyllables
                .map(word => word.split(" ")
                                 .map(w => w.split("-")
                                            .map(s => ({syllable: s, timing: syllableTiming[iCpt++]}))))
    
    
            return rhythmSyllables;
        }
        
        return [];
    }
    
    draw ()
    {
        let isReady = this.canvasIsReady && this.assetsAreLoaded && this.appIsBuilt;

        if (!isReady)
        {
            return
        }

        this.rhythmSyllables = this.getSyllablesTiming();

       // Constants.init();
        let {width, height} = Display.getSize();

        let duration = 0

        if (this.audio)
        {
            duration = this.audio.duration();
        }



        if (duration > 0)
        {
            this.duration = duration;
            this.currentWidth = width;
            this.currentHeight = height;


            this.drawBackground();

            this.spScrollable = new PIXI.Container();
            this.container.addChild(this.spScrollable);

            this.drawSeparator();


            this.prepareRhythm();

            this.drawMusicalGuideForPlayers(true, this.playersHits);

            this.drawUI();



            this.isDrawn = true;

            this.onDrawComplete();
        }
        else
        {

            setTimeout( () => this.draw(), 100);
        }
    }

    prepareRhythm ()
    {
        this.arrRhythm = [];
    }

    onDrawComplete ()
    {

    }

    drawBackground ()
    {
        this.spBackground = new PIXI.Graphics();

        this.spBackground.beginFill(0x37746d);
        this.spBackground.drawRect(0, 0, this.currentWidth, this.currentHeight);
        this.spBackground.endFill();

        this.container.addChild(this.spBackground);


        this.spBackgroundPlayHead = new PIXI.Graphics();

        this.spBackgroundPlayHead.beginFill(0x244e50, 0.95);
        this.spBackgroundPlayHead.drawRect(0, 0, this.currentWidth, this.currentHeight);
        this.spBackgroundPlayHead.endFill();

        this.spBackgroundPlayHead.width = 0;



        this.container.addChild(this.spBackgroundPlayHead);
    }

    getSongWidth ()
    {
        let width = this.currentWidth - this.startOffsetX ;

        if (this.mode === "game")
        {
            width = (this.currentWidth * 12) - this.startOffsetX;
        }

        return width;
    }

    drawSeparator ()
    {
        this.spSeparatorContainer = new PIXI.Container();

        let iSeparator = 45;

        this.gameWidth = this.getSongWidth();

        let iSize = this.gameWidth / (iSeparator);
        this.oneBeatWidth = iSize;
        this.firstSeparatorX = this.startOffsetX ;
        let arrSections = this.playersHits.bars.sections;




        //VERTICAL LINE - THESES MIGHT SCROLL
        for (let i = 0; i <= iSeparator; i++)
        {


        }


        //HORIZONTAL LINE - THESE WON'T SCROLL
        let iHeightOffset = this.currentHeight / 3;
        this.arrSpLines = [];

        for (let i = 1; i <= 2; i++)
        {
            let line = new PIXI.Graphics();
            line.lineStyle(2, 0x69c3b0, 1);
            line.moveTo(0,0);
            line.lineTo(this.currentWidth, 0);

            line.y = iHeightOffset * i;
            this.container.addChild(line);

            this.arrSpLines.push(line);
            this.arrFadeOutables.push(line);

        }


        this.spScrollable.addChild(this.spSeparatorContainer);

    }



    drawMusicalGuideForPlayers (redraw = false, players = null)
    {
        if (!players)
            players = this.playersHits;

        if (!this.isDrawn || redraw)
        {
            let textureCircle = Library.getTextureFromAtlas("ui", "circle.png");
            let textureSquare = Library.getTextureFromAtlas("ui", "square.png");
            let textureTriangle = Library.getTextureFromAtlas("ui", "triangle.png");
            let textureFeuille = Library.getTextureFromAtlas("ui", "feuille.png");
            
            let textureFeuilleBleu = Library.getTextureFromAtlas("ui", "feuille_bleu.png");
            let textureFeuilleJaune = Library.getTextureFromAtlas("ui", "feuille_jaune.png");
            let textureFeuilleRose = Library.getTextureFromAtlas("ui", "feuille_rose.png");

            if (redraw && this.spScrollable)
            {
                //TODO: remove potential memory leak
                this.spScrollable.removeChild(this.rhythm)
                this.spScrollable.removeChild(this.player1)
                this.spScrollable.removeChild(this.player2)
                this.spScrollable.removeChild(this.player3)
            }


            let strDifficulty = ["difficulty" + this.difficulty];
            let hasRhythm = (this.arrRhythm ?? []).length > 0;

            if (this.mode === "game")
            {
                //this.rhythm = this.drawRhythmLane();
                //this.spScrollable.addChild(this.rhythm);

                if (this.karaoke)
                    this.container.removeChild(this.karaoke);

                this.karaoke = this.drawKaraoke();
                
                if (this.karaoke)
                {
                    this.karaoke.x = 110;
                    this.karaoke.y = this.iPaddingKaraoke;
                    this.container.addChild(this.karaoke);
    
                    this.arrSyllables = this.rhythmSyllables.map(p => p.flat()).flat().reverse();
                }
                else
                {
                    this.karaokeOver = true;
                    
    
                    let tween = {alpha: 1, duration: 1};
                    _.forEach(this.arrFadeOutables, (sp) =>
                    {
                        sp.alpha =  1;
        
                    });
                }


            }
            else if (this.mode === "preview" && hasRhythm)
            {
                let {width, height} = Display.getSize();
    
    
    
                
                let firstSectionEnd = this.playersHits.sections.find( h => h !== null);


                let widthSection = this.getPositionFromHit(firstSectionEnd);

                let text = this.lang === "fr" ? "Parler-rythmé" : "Rhythmic words";

                this.rhythmStyle = new PIXI.TextStyle({fontFamily: "Poppins",fontSize: 30, fill:"#FFFFFF"});

                let metrics = PIXI.TextMetrics.measureText(text, this.rhythmStyle);

                //we decrment fontsize until the text fit
                while (metrics.width > (widthSection * 0.75) && this.rhythmStyle.fontSize > 5)
                {
                    this.rhythmStyle.fontSize -= 1;
                    metrics = PIXI.TextMetrics.measureText(text, this.rhythmStyle);
                }

                if (this.rhythmStyle.fontSize > 5)
                {
                    this.rhythm = new PIXI.Text(text, this.rhythmStyle); //this.drawScrolleeRythmnLane();
                    this.spScrollable.addChild(this.rhythm);
    
    
                    this.rhythm.x = (widthSection / 2) - (metrics.width / 2);
                    this.rhythm.y = (height / 2) - (this.rhythm.height / 2);
                }

                // let gfx = new PIXI.Graphics();
                // gfx.beginFill(0x990000, 1);
                // gfx.drawRect(0,0,widthSection, height );
                // gfx.endFill();
                //
                // this.spScrollable.addChild(gfx);

                //this.rhythm.mask = gfx;
            }

            this.player1 = this.drawMusicalGuidePlayer([textureSquare, textureFeuilleBleu], players.player1[strDifficulty]);
            this.player2 = this.drawMusicalGuidePlayer([textureTriangle, textureFeuilleJaune], players.player2[strDifficulty]);
            this.player3 = this.drawMusicalGuidePlayer([textureCircle, textureFeuilleRose], players.player3[strDifficulty]);
        }

        this.distributePlayerLane();

    }

    distributePlayerLane ()
    {
        let gotLines = Array.isArray(this.arrSpLines) && this.arrSpLines.length >= 2;

        if (this.numbersOfTeams === 3)
        {
            let iHeightOffset = this.currentHeight / 3;
            this.player1.y = iHeightOffset * 2 + (iHeightOffset / 2);
            this.player2.y = this.currentHeight / 2;
            this.player3.y = iHeightOffset / 2;

            this.player2.visible = true;
            this.player3.visible = true;

            if (gotLines)
            {
                this.arrSpLines[0].y = iHeightOffset;
                this.arrSpLines[1].y = iHeightOffset * 2;
                this.arrSpLines[0].visible = true;
                this.arrSpLines[1].visible = true;
            }
        }
        else if (this.numbersOfTeams === 2)
        {
            let iHeightOffset = this.currentHeight / 4;

            this.player1.y = iHeightOffset * 3;
            this.player2.y = iHeightOffset;

            this.player2.visible = true;
            this.player3.visible = false;

            if (gotLines)
            {
                this.arrSpLines[0].y = this.currentHeight / 2;
                this.arrSpLines[0].visible = true;
                this.arrSpLines[1].visible = false;
            }
        }
        else if (this.numbersOfTeams === 1)
        {
            let iHeightOffset = this.currentHeight / 2;

            this.player1.y = iHeightOffset;

            this.player2.visible = false;
            this.player3.visible = false;

            if (gotLines)
            {
                this.arrSpLines[0].visible = false;
                this.arrSpLines[1].visible = false;
            }
        }
    }

    drawMusicalGuidePlayer (textures,  hits)
    {

        let player = this.drawMusicalGuide(textures, hits);

        //player.y = y;

        this.spScrollable.addChild(player);

        return player;
    }


    getFirstSectionEnd ()
    {
        let firstSectionEnd = (this.playersHits?.sections ?? []).sort( (a,b) => a-b).find( h => h !== null)

        return firstSectionEnd ?? 0;
    }
    drawMusicalGuide (textures, hits)
    {
        let guide = new PIXI.Container();
        let firstSectionEnd = this.getFirstSectionEnd();


        if (hits && Array.isArray(hits))
        {
            let iOffsetX = this.startOffsetX ;

            hits = hits.sort( (a,b) => a.hit - b.hit);

            let goalWidth = this.oneBeatWidth / 5;


            if (goalWidth > 50)
            {
                goalWidth = 50;
            }

            for (let i = 0; i < hits.length; i++)
            {
                let hit = hits[i];

                if (hit.size === 0.5)
                {
                    hit.size = 0.75;
                }

                let guideItem;
                
                let hasRhythm = (this.arrRhythm ?? []).length > 0;
                
                if (this.constructor.name === "Editor" && hit.hit < firstSectionEnd && hasRhythm)
                {
                   guideItem = new PIXI.Graphics();
                   guideItem.beginFill(0,1);
                   guideItem.drawCircle(0,0, goalWidth / 4);
                   guideItem.endFill();
    
                    // guideItem = new PIXI.Sprite(textures[hit.shape ?? 0]);
                    // guideItem.width = goalWidth * hit.size ;
                    // guideItem.hitSize = hit.size ;

                }
                else if (this.constructor.name !== "Editor" && this.mode === "preview" && hit < firstSectionEnd && hasRhythm)
                {
                   // guideItem = new PIXI.Graphics();
                   // guideItem.beginFill(0,1);
                   // guideItem.drawCircle(0,0, goalWidth / 4);
                   continue;
               }

                else if (hit.hit >= firstSectionEnd || !hasRhythm)
                {
                    guideItem = new PIXI.Sprite(textures[hit.shape ?? 0]);
                    guideItem.width = goalWidth * hit.size ;
                    guideItem.hitSize = hit.size ;
                }
                else
                {
                    continue;
                }



                this.baseScale = guideItem.scale.y = guideItem.scale.x;
                guideItem.anchor = {x: 0.5, y: 0.5}


                //guideItem.x = iOffsetX + this.getPositionFromHit(hit);
                guideItem.x = this.getPositionFromHit(hit.hit);

                guideItem.hit = hit.hit;


                if ((this.mode !== "game") || ((this.mode === "game")))// && guideItem.x > this.startOffsetX) )
                {
                    guide.addChild(guideItem);
                }

            }
        }

        return guide;
    }

    findKaraokeHeight ()
    {
        let strRhythmSyllable = this.playersHits.rhythm[this.lang].replace(/ +(?= )/g,'').replaceAll("\\n", "\n");
        let iFontSize = 16;

        let {width, height} = Display.getSize();
        let sourceHeight = height;

        height -= (this.iPaddingKaraoke * 2); //padding top and bottom

        let style= new PIXI.TextStyle({fontFamily: "Poppins", fontSize: iFontSize, fill:"#FFFFFF"});
        let metrics = PIXI.TextMetrics.measureText(strRhythmSyllable, style);

        if (metrics.height > height)
        {
            this.iPaddingKaraoke = 20;

            height = sourceHeight - (this.iPaddingKaraoke * 2); //padding top and bottom
        }

        if (metrics.height < height)
        {
            while (metrics.height < height)
            {
                iFontSize++;
                style = new PIXI.TextStyle({fontFamily: "Poppins", fontSize: iFontSize, fill: "#FFFFFF"});

                metrics = PIXI.TextMetrics.measureText(strRhythmSyllable, style);
            }
        }

        return {metrics, style};
    }
    drawKaraoke ()
    {


        let spKararaoke = new PIXI.Container();

        let iCountPhrase = this.rhythmSyllables.length;
        
        if (iCountPhrase === 0)
        {
            return;
        }

        let size = this.findKaraokeHeight();

        let strText = "";
        let style = size.style;

        for (let i = 0; i < iCountPhrase; i++)
        {
            let phrase = this.rhythmSyllables[i];

            let iCountWord = phrase.length;

            for (let j = 0; j < iCountWord; j++)
            {
                let word = phrase[j];

                let iCountSyllable = word.length;

                for (let k = 0; k < iCountSyllable; k++)
                {
                    let syllable = word[k].syllable;

                    strText += syllable;

                    let metrics = PIXI.TextMetrics.measureText(strText, style);
                    let spSyllable = new PIXI.Container();
                    let txtSyllable = new PIXI.Text(syllable, style);
                    txtSyllable.updateText();

                    spSyllable.addChild(txtSyllable);

                    spSyllable.x = metrics.lineWidths[i] - spSyllable.width;
                    spSyllable.y = metrics.height - spSyllable.height;
                    txtSyllable.tint = 0xFFFFFF;

                    spKararaoke.addChild(spSyllable);

                    word[k].container = txtSyllable;
                    //console.log(i, j, k, syllable, metrics.width, metrics.height, spSyllable.x, spSyllable.y);

                }

                strText += " ";
            }

            strText += "\n";
        }





        this.karaokeOver = false;

        return spKararaoke;
    }

    getKaraokeText ()
    {
        let strRhythmSyllable = this.playersHits.rhythm[this.lang]
                                        .replace(/ +(?= )/g,'')
                                        .replace(/\-/g, "")
                                        .replace(/\\n/g, "\n");



        return strRhythmSyllable;
    }

    drawScrolleeRythmnLane ()
    {
        let guide = new PIXI.Container();
        let arrRhythm = this.arrRhythm;

        let iOffsetX = this.startOffsetX ;
        let firstSectionEnd = this.playersHits.sections.find( h => h !== null)
        let bComplete = false;

        for (let i = 0; i < arrRhythm.length && !bComplete; i++)
        {
            let hit = arrRhythm[i];

            if (hit >= firstSectionEnd)
            {
                bComplete = true;
                continue;
            }

            let sy = new Syllable(this.rhythmSyllables[i]);
            //let sy = new Syllable("X" + i);

            let info =  this.getPositionInfoFromHit(hit);
            sy.x = info.ratio * info.songWidth * this.rythmMultiplier;


            if ( sy.x < firstSectionEnd)
            {

                guide.addChild(sy);
            }


        }

        return guide;
    }


    drawRhythmLane ()
    {

        let guide = new PIXI.Container();
        let arrRhythm = this.arrRhythm;

        let iOffsetX = this.startOffsetX ;
        let firstSectionEnd = this.playersHits.sections.find( h => h !== null)
        let bComplete = false;

        for (let i = 0; i < arrRhythm.length && !bComplete; i++)
        {
            let hit = arrRhythm[i];

            if (hit >= firstSectionEnd)
            {
                bComplete = true;
                continue;
            }

            let sy = new Syllable(this.rhythmSyllables[i]);
            //let sy = new Syllable("X" + i);

            sy.x =  this.getPositionFromHit(hit);


            if ( (this.mode === "game") && sy.x < firstSectionEnd)
            {
                //let offsetHeight =  this.currentHeight / (this.numbersOfTeams);
                let offsetHeight =  this.currentHeight / (3);
                //
                // sy.y = (offsetHeight / 2) + offsetHeight * (i % this.numbersOfTeams);
                 sy.y = (offsetHeight / 2) + offsetHeight * (i % 3);
                 guide.addChild(sy);
            }


        }

        return guide;
    }

    getPositionInfoFromHit (hit)
    {
        let ratio = hit / (this.duration * 1000);;

        let songWidth = this.getSongWidth();

        let pos = {x: ratio * songWidth, ratio, songWidth};

        return pos;
    }

    getPositionFromHit (hit)
    {
        let pos = this.getPositionInfoFromHit(hit)

        return pos.x;
    }

    getHitFromPosition (position)
    {

        let songWidth = this.getSongWidth() /*- this.firstSeparatorX*/;

        let ratio = position / songWidth;


        return Math.round(ratio * (this.duration * 1000));
    }

    getGuideOffset ()
    {
        let x = Math.random() > 0.5 ? (Math.random() > 0.5 ? this.oneBeatWidth : this.oneBeatWidth / 2) : (Math.random() > 0.5 ? this.oneBeatWidth / 3 : this.oneBeatWidth / 4);

        return (4 - this.difficulty) * x;

    }


    drawUI ()
    {
        let {width, height} = Display.getSize();

        this.ui = new PIXI.Container();

        let textureBtnPlay = Library.getTextureFromAtlas("ui", "play.png");
        this.btnPlayPause = new PIXI.Sprite(textureBtnPlay);
        this.btnPlayPause.anchor = {x:0.5, y:0.5};

       this.btnPlayPause.width = Math.min(this.startOffsetX - 10, height / 3);

        this.btnPlayPause.scale.y = this.btnPlayPause.scale.x;

        this.btnPlayPause.x = this.btnPlayPause.width / 2 + 5;
        this.btnPlayPause.y = this.currentHeight / 2;

        this.btnPlayPause.interactive = true;
        this.btnPlayPause.on("pointerup", this.togglePlayPause.bind(this))




        let texturePlayHead = Library.getTextureFromAtlas("ui", "playhead.png");
        this.spPlayHead = new PIXI.Sprite(texturePlayHead);

        this.spPlayHead.x = -100;
        this.spPlayHead.height = this.currentHeight;

        this.arrFadeOutables.push(this.spPlayHead);



        this.ui.addChild(this.btnPlayPause);
        this.ui.addChild(this.spPlayHead);


        this.container.addChild(this.ui);
    }




    addEvents ()
    {
        // this.ui.on("change-lang", () => this.onChangeLang());
        // this.ui.on("change-size", () => this.onChangeTextSize());
        // this.ui.on("fullscreen", () => this.onFullscreen());
    }

    onResize (responsive)
    {
        return false;
        let {width, height} = Display.getSize();


        if (true || Constants.ready)
        {
            //let rr = Constants.ResponsiveRatio;

            // if (height > window.innerHeight)
            // {
            //     height = window.innerHeight * 0.99; //google recommends using 99% of available height for some reason
            // }

            if (this.rhythm)
            {
                this.rhythmStyle = new PIXI.TextStyle({fontFamily: "Poppins",fontSize: 22, fill:"#FFFFFF"});
                this.rhythm.text = ("Hello I am resize")
            }

            this.app.renderer.resize(width, height);


            this.ui.resize(width, height);
        }
        else
        {
            setTimeout(() => this.onResize(responsive), 100);
        }

    }


    onChangeLang ()
    {
        document.documentElement.lang = Lang.lang;
    }

    onChangeTextSize ()
    {

    }

    async onFullscreen ()
    {


        if (!document.fullscreenElement)
        {
            let response = await this.canvas.requestFullscreen();
            this.isFullscreen = true;



            try
            {
                let o = await window.screen.orientation.lock("landscape");
            }

            catch (e)
            {

            }


            setTimeout(() => this.onResize(), 50);

        }
        else
        {
            document.exitFullscreen();
        }
    }


    togglePlayPause ()
    {
        if (this.isPlaying)
        {
            this.pause();
            this.btnPlayPause.texture = Library.getTextureFromAtlas("ui", "play.png")


        }
        else
        {
            //this.songId = this.audio.play();
            this.play();
            this.btnPlayPause.texture = Library.getTextureFromAtlas("ui", "pause.png")

            this.whilePlaying(true);
        }


    }

    pause ()
    {
        this.audio.pause(this.songId);
        this.isPlaying = false;
    }

    play ()
    {
        let d = this.audio.duration();
        this.songId = this.audio.play();
        this.isPlaying = true;
    }

    moveScrollToSeekPos ()
    {
        let duration = this.audio.duration();
        let current = this.audio.seek();

        let ratio = current / duration;

        let pos = this.getSongWidth() * ratio;
        this.spPlayHead.x = 300; //;

        this.spScrollable.x = 300 - pos;

        return pos;
    }

    whilePlaying (first = false)
    {
        if (first || this.isPlaying)
            requestAnimationFrame(this.fctWhilePlaying);

        let duration = this.audio.duration();
        let current = this.audio.seek();

        let ratio = current / duration;
        let songWidth = this.getSongWidth()
        this.spPlayHead.x = this.startOffsetX  + (songWidth  * ratio);
        this.spBackgroundPlayHead.width = this.spPlayHead.x;
        //this.rhythm.x = (this.spPlayHead.x - this.startOffsetX) - (((songWidth * this.rythmMultiplier)  * ratio));



    }

    reset ()
    {
        while (this.spSeparatorContainer.children.length > 0)
        {
            this.spSeparatorContainer.removeChildAt(0);
        }

        this.player1.parent.removeChild(this.player1);
        this.player2.parent.removeChild(this.player2);
        this.player3.parent.removeChild(this.player3);

        this.drawSeparator();
        this.drawMusicalGuideForPlayers(true, this.playersHits)
    }


}


