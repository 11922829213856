import Library from "../../Library.js";


export default class AudioHelper
{
    constructor ()
    {
        if (AudioHelper.instance)
        {
            throw("You can't instantiate AudioHelper directly");
        }

        AudioHelper.instance = this;
        this.objPlaying = {};

        let currentVolume = window.localStorage.getItem("volume");

        if (currentVolume === null)
        {
            currentVolume = "true";
            window.localStorage.setItem("volume", currentVolume.toString());
        }

        this.bCanPlay =  JSON.parse(currentVolume);
    }

    stopAllPlaying ()
    {
        for (let strId in this.objPlaying)
        {
            AudioHelper.stopSound(strId);
        }

    }

    setAsPlaying (id)
    {
        this.objPlaying[id] = true;
    }

    removeAsPlaying (id)
    {
        delete this.objPlaying[id];
    }

    get canPlay ()
    {
        return this.bCanPlay;
    }

    set canPlay (bCanPlay)
    {
        this.bCanPlay = bCanPlay;
    }

    static playSound(strSprite = "", canBeStop = false)
    {
        if (!AudioHelper.instance.canPlay)
        {
            return -1;
        }

        let audio = Library.getAudio("audio-audios");
        let id =  strSprite !== "" ? audio.play(strSprite) : audio.play();

        if (canBeStop)
            AudioHelper.instance.setAsPlaying(id);

        return id;
    }

    static pauseSound (audioID)
    {
        let audio = Library.getAudio("audio-audios");
        audio.pause(parseInt(audioID));

        AudioHelper.instance.removeAsPlaying(audioID);
    }

    static stopSound (audioID)
    {
        let audio = Library.getAudio("audio-audios");
        audio.stop(parseInt(audioID));

        AudioHelper.instance.removeAsPlaying(audioID);
    }

    static toggleVolume ()
    {
        AudioHelper.instance.canPlay = !AudioHelper.instance.canPlay;

        window.localStorage.setItem("volume", AudioHelper.instance.canPlay.toString());

        if (!AudioHelper.instance.canPlay)
        {
            AudioHelper.instance.stopAllPlaying();
        }

        return AudioHelper.instance.canPlay;
    }
}

let __audio_helper__ = new AudioHelper();