import Display from "./game/utils/Display.js";
import Library from "./Library.js";

export default class Constants
{
    constructor ()
    {
        if (Constants.instance)
        {
            throw("Do not instantiate Style directly");
            return;
        }

        Constants.instance = this;
        this.isReady = false;
    }

    static init ()
    {
        Constants.instance.initData();
    }

    initData ()
    {
        //All values are based on a width of BASE_WIDTH  (1920px)
        //Loaded from the constants.json file
        this.objStyles = Library.getData("constants");
        this.isReady = true;

    }


    static get ready () { return Constants.instance.isReady; }
    static get HUD_GAP_BTN () { return Constants.instance.objStyles.HUD_GAP_BTN; }
    static get HUD_PADDING_RIGHT () { return Constants.instance.objStyles.HUD_PADDING_RIGHT; }
    static get HUD_PADDING_TOP () { return Constants.instance.objStyles.HUD_PADDING_TOP; }
    static get BUTTON_FONT_SIZE () { return Constants.instance.objStyles.BUTTON_FONT_SIZE; }
    static get ITEM_FONT_SIZE () { return Constants.instance.objStyles.ITEM_FONT_SIZE; }
    static get TIMER_FONT_SIZE () { return Constants.instance.objStyles.TIMER_FONT_SIZE; }
    static get TITLE_COLOR () { return Constants.instance.objStyles.TITLE_COLOR; }
    static get BUTTON_TEXT_COLOR () { return Constants.instance.objStyles.BUTTON_TEXT_COLOR; }
    static get TEXT_COLOR () { return Constants.instance.objStyles.TEXT_COLOR; }
    static get ICON_COLOR () { return Constants.instance.objStyles.ICON_COLOR; }
    static get ILLUSTRATION_COLOR () { return Constants.instance.objStyles.ILLUSTRATION_COLOR; }
    static get DESK_COLOR_END () { return Constants.instance.objStyles.DESK_COLOR_END; }
    static get SPLASH_PADDING_BOTTOM () { return Constants.instance.objStyles.SPLASH_PADDING_BOTTOM; }
    static get EXIT_PADDING_BOTTOM () { return Constants.instance.objStyles.EXIT_PADDING_BOTTOM; }
    static get SPLASH_TEXT_WIDTH () { return Constants.instance.objStyles.SPLASH_TEXT_WIDTH; }
    static get LARGE_TITLE_WIDTH () { return Constants.instance.objStyles.LARGE_TITLE_WIDTH; }
    static get SPLASH_TEXT_PADDING_BOTTOM () { return Constants.instance.objStyles.SPLASH_TEXT_PADDING_BOTTOM; }
    static get SPLASH_TITLE_PADDING_BOTTOM () { return Constants.instance.objStyles.SPLASH_TITLE_PADDING_BOTTOM; }
    static get GAMEPLAY_TITLE_PADDING_VERTICAL () { return Constants.instance.objStyles.GAMEPLAY_TITLE_PADDING_VERTICAL; }
    static get TIMER_TEXT_PADDING_HORIZONTAL () { return Constants.instance.objStyles.TIMER_TEXT_PADDING_HORIZONTAL; }
    static get TIMER_PADDING_LEFT () { return Constants.instance.objStyles.TIMER_PADDING_LEFT; }
    static get CHECKLIST_GAP_ITEM () { return Constants.instance.objStyles.CHECKLIST_GAP_ITEM; }
    static get CHECKLIST_PADDING_VERTICAL () { return Constants.instance.objStyles.CHECKLIST_PADDING_VERTICAL; }
    static get CHECKLIST_PADDING_ITEM () { return Constants.instance.objStyles.CHECKLIST_PADDING_ITEM; }
    static get CHECKLIST_ITEM_PADDING_LEFT () { return Constants.instance.objStyles.CHECKLIST_ITEM_PADDING_LEFT; }
    static get CHECKLIST_ITEM_CHECK_PADDING_RIGHT () { return Constants.instance.objStyles.CHECKLIST_ITEM_CHECK_PADDING_RIGHT; }
    static get CURRENT_OBJECT_PADDING_BOTTOM () { return Constants.instance.objStyles.CURRENT_OBJECT_PADDING_BOTTOM; }
    static get CURRENT_OBJECT_TEXT_PADDING () { return Constants.instance.objStyles.CURRENT_OBJECT_TEXT_PADDING; }
    static get FONT_FAMILY_TEXT () { return Constants.instance.objStyles.FONT_FAMILY_TEXT; }
    static get FONT_FAMILY_TITLE () { return Constants.instance.objStyles.FONT_FAMILY_TITLE; }
    static get FONTSIZE_TITLE_LARGE () { return Constants.instance.objStyles.FONTSIZE_TITLE_LARGE; }
    static get FONTSIZE_TITLE_SMALL () { return Constants.instance.objStyles.FONTSIZE_TITLE_SMALL; }
    static get BTN_BG_COLOR () { return Constants.instance.objStyles.BTN_BG_COLOR; }
    static get ITEM_BG_COLOR_START () { return Constants.instance.objStyles.ITEM_BG_COLOR_START; }
    static get ITEM_BG_COLOR_CHECK () { return Constants.instance.objStyles.ITEM_BG_COLOR_CHECK; }
    static get BASE_WIDTH () { return Constants.instance.objStyles.BASE_WIDTH; }
    static get BASE_HEIGHT () { return Constants.instance.objStyles.BASE_HEIGHT; }
    static get WAIT_BEFORE_END_GAME () { return Constants.instance.objStyles.WAIT_BEFORE_END_GAME; }
    static get ROTATE_SCREEN_BG_COLOR () { return Constants.instance.objStyles.ROTATE_SCREEN_BG_COLOR; }
    static get ROTATE_PADDING_TOP () { return Constants.instance.objStyles.ROTATE_PADDING_TOP; }
    static get FONTSIZE_TITLE_ROTATE_SCREEN () { return Constants.instance.objStyles.FONTSIZE_TITLE_ROTATE_SCREEN; }
    static get GAMEPLAY_HIDE_TITLE_SCREEN_RATIO () { return parseFloat(Constants.instance.objStyles.GAMEPLAY_HIDE_TITLE_SCREEN_RATIO); }

    static get ResponsiveRatio ()
    {
        let {width, height} = Display.getSize();

        return width / Constants.BASE_WIDTH;
    }

    static get ResponsiveRatioVertical ()
    {
        let {width, height} = Display.getSize();

        return height / Constants.BASE_HEIGHT;
    }
}


let __style = new Constants();