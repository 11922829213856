"use strict";

import Game from "./game/Game.js";
import Editor from "./game/Editor.js";

function onReady ()
{
	console.log("Partitions Codées");
	console.log("Compilation date", __COMPILATION_DATE__); //this will be outputted by webpack

	let strBodyID = document.querySelector("body").getAttribute("id");
	

	if (strBodyID === "editor")
	{
		let strID = document.querySelector("[data-id]").getAttribute("data-id");
		
		window.history_id = parseInt(strID);
		window.game = new Editor("editorCanvas", strID);
	}
	else //inside the game
	{
		console.log("vue?", window.vue);
		window.addEventListener("app-is-ready", prepareGame)
		window.addEventListener("song-change", onSongChange)
	}

}

function prepareGame (e)
{
	console.log("prepareGame", e);
	
	window.history_id = parseInt(e.detail.id);
	
	window.game = new Game("gamePreview");
	window.dispatchEvent(new Event("game-is-ready"));
}

function onSongChange (e)
{
	window.history_id = parseInt(e.detail.id);
}





document.addEventListener('DOMContentLoaded', onReady);