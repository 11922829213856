import {Howl,Howler} from "howler";

export default class Library
{
    constructor ()
    {
        Library.instance = this;

        this.datas = {};
        this.audios = {}
    }

    initialize (resources)
    {
        if (this.resources) //we want to merge with existing resources
            resources = Object.assign(this.resources, resources);

        this.resources = resources;

        this.textures = [];
    }

    static arrayBufferToBase64( buffer )
    {
        let binary = '';
        let bytes = new Uint8Array( buffer );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    static addAudio (key,audio)
    {
        // let src = ["data:" + type + ";base64," + Library.arrayBufferToBase64(arrayBuffer)];
        //
        // let sprite = Library.instance.datas.audios_sprites;
        //
        //
        // let howl = sprite ? new Howl({ src, sprite }) : new Howl({ src });
        Library.instance.audios[key] = audio;
    }

    static getAudio (key)
    {
        return Library.instance.audios[key];
    }

    static addData (key, data)
    {
        Library.instance.datas[key] = data;
    }

    static getData (key)
    {
        return Library.instance.datas[key];
    }

    static setResources (resources)
    {
        Library.instance.initialize(resources);
    }

    static getSpritesheet (strKey)
    {
        return Library.instance.resources[strKey].spritesheet;
    }

    static getAnimation (strKey)
    {
        //console.log("getAnimations", strKey);
        let spritesheet = Library.getSpritesheet(strKey);
        let arrKeys = Object.keys(spritesheet.animations);

        if(arrKeys.length > 0)
        {
            let strKeyAnimations = arrKeys.shift();

            return spritesheet.animations[strKeyAnimations];
        }

        return null;
    }

    static getTextureFromResources (strKey)
    {
        if (!Library.instance.resources[strKey])
        {
            console.log("%c is not a valid resources in the Library", strKey);
        }

        return Library.instance.resources[strKey].texture;
    }

    static getTextureFromAtlas(strAtlas, strKey)
    {
        if (Library.instance.resources[strAtlas] === undefined)
        {
            let a = 1;
        }
        return Library.instance.resources[strAtlas].textures[strKey];
    }

    static getTexture (strKey)
    {
        return Library.instance.textures[strKey];
    }
    
    
    //UTILS
    static getAudiospriteDuration (strAudio, strSprite)
    {
        let audio = Library.getData(strAudio);

        if (!audio[strSprite])
            return -1;

        return audio[strSprite][1];
    }
}

let __library__ = new Library();