
import * as PIXI from "pixi.js";

export default class Slider extends PIXI.Container
{
    constructor(options)
    {
        super();

        this.options = options;

        this.createClosure ();
        this.build();
        this.initialize()
    }

    createClosure ()
    {
        this.fctStartDrag = this.startDrag.bind(this);
        this.fctDrag = this.drag.bind(this);
        this.fctStopDrag = this.endDrag.bind(this);
    }

    build ()
    {
        this.labelStyle = new PIXI.TextStyle({fontFamily: "Poppins",fontSize: 14});
        this.label = new PIXI.Text(this.options.label, this.labelStyle);

        let metrics = PIXI.TextMetrics.measureText(this.options.label, this.labelStyle);

        this.label.x = (100 - metrics.width) / 2;

        this.addChild(this.label);

        this.line = new PIXI.Graphics();
        this.line.beginFill(0x999999, 1);
        this.line.drawRect(5, 0, 100, 10);
        this.line.endFill();

        this.line.y = metrics.height + 15;

        this.addChild(this.line);

        this.btn = new PIXI.Graphics();
        this.btn.lineStyle(1, 0x000000, 1);
        this.btn.beginFill(0xFFFFFF, 1);
        this.btn.drawRect(0, 0, 10, 30);
        this.btn.endFill();

        this.btn.x = 60;
        this.btn.y = this.line.y  -10;


        this.btn.interactive = true;
        this.btn.buttonMode = true;
        this.btn.on("pointerdown", this.fctStartDrag);
        this.addChild(this.btn);

        this.value = new PIXI.Text(this.options.value, this.labelStyle);

        this.addChild(this.value);




    }

    initialize ()
    {
        this.range = this.options.max - this.options.min;

        let x = this.convertValueToPosition(this.options.value);

        this.btn.x = x;

        this.positionValue();
    }

    convertValueToPosition (value)
    {
        let valueNormalize = value - this.options.min;

        let ratio = valueNormalize / this.range;

        return ratio * 100;
    }

    convertPositionToValue (posX)
    {
        let ratio = posX / 100;

        let rangeRatio = ratio * this.range;

        return (rangeRatio + this.options.min).toFixed(2);
    }

    positionValue ()
    {
        let metrics = PIXI.TextMetrics.measureText(this.value.text, this.labelStyle);

        this.value.x = this.btn.x;
        this.value.y = this.btn.y + this.btn.height + 5;
    }

    startDrag (e)
    {
        document.addEventListener("pointermove", this.fctDrag);
        document.addEventListener("pointerup", this.fctStopDrag);
    }

    drag (e)
    {
        let screenPos = {x: e.clientX, y: e.clientY};
        let localPos = this.toLocal(screenPos);

        let newValue = this.convertPositionToValue(localPos.x);

        if (newValue >= this.options.min && newValue <= this.options.max)
        {
            this.btn.x = localPos.x;

            this.value.text = this.convertPositionToValue(this.btn.x);
            this.positionValue();

            this.emit("change", newValue)
        }

    }

    endDrag (e)
    {
        document.removeEventListener("pointermove", this.fctDrag);
        document.removeEventListener("pointerup", this.fctStopDrag);
    }
}