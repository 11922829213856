import * as PIXI from "pixi.js";
import "pixi-tps-loader";
import Library from "../Library.js";
import Loader from "./Loader.js";

export default class PIXILoader extends PIXI.utils.EventEmitter
{
    constructor()
    {
        super();
        this.loader = new PIXI.Loader();
        this.loader.onProgress.add ( (a,b) => this.onProgress(a,b))
        this.loader.onLoad.add ( (a, b) => this.onLoad(a,b))

       // this.loadAsset("assets", _strWhatToLoad)

    }


    loadAsset(key, url)
    {
        this.loader.add(key, url);
        this.loader.load(this.onAssetsLoaded.bind(this));
    }

    loadGroup (arrAssets, strBasePath = "")
    {
        //let strGroup = this.arrGroups.shift();

        //let arrAssets = this.resources.assets.data[strGroup];

        for (let i  = 0; i < arrAssets.length; i++)
        {
            let strURL = arrAssets[i].url;
            let strKey = arrAssets[i].key

            strURL = Loader.addVersionToURL(strURL);

            this.loader.add(strKey, strBasePath + strURL)
        }


        this.loader.load(this.onGroupLoaded.bind(this, arrAssets))
    }

    mergeMultipack (asset, resources, strAnim = "")
    {
        let strNewKey = asset.newKey;
        let strOldKey = asset.key;

        let bIsAnim = strAnim != "";


        Object.defineProperty(resources, strNewKey,
            Object.getOwnPropertyDescriptor(resources, strOldKey));

        delete resources[strOldKey];
        delete resources[strOldKey + "_image"];

        if (resources[strNewKey].data.meta.related_multi_packs) //there is some related asset to load
        {
            let arrRelateds = resources[strNewKey].data.meta.related_multi_packs;


            for (let i = 0; i < arrRelateds.length; i++)
            {
                let strRelated = arrRelateds[i].replace(".json", "");

                let assets = resources[strRelated];

                let objTextures = assets.textures;
                Object.assign(resources[strNewKey].textures, objTextures);

                if (bIsAnim)
                {
                    let arrAnimations = assets.spritesheet.animations[strAnim];
                    resources[strNewKey].spritesheet.animations[strAnim] = [...resources[strNewKey].spritesheet.animations[strAnim], ...arrAnimations];

                    let objTextures = assets.spritesheet.textures;
                    Object.assign(resources[strNewKey].spritesheet.textures, objTextures);

                    let objFrames = assets.spritesheet._frames;
                    Object.assign(resources[strNewKey].spritesheet._frames, objFrames);

                    let framesKeys = assets.spritesheet._frameKeys;
                    resources[strNewKey].spritesheet._frameKeys = [...resources[strNewKey].spritesheet._frameKeys, ...framesKeys]
                }

                delete resources[strRelated];
                delete resources[strRelated + "_image"];

            }
        }


    }


    onAssetsLoaded (loader, resources)
    {

        
        this.resources = resources;
        this.arrGroups = ["images","atlas","data"];


        this.loadGroup();
    }

    onGroupLoadedAction (arrAssets, resources)
    {
        for (let i = 0; i < arrAssets.length; i++)
        {
            let asset = arrAssets[i];

            if (asset.type)
            {
                if (asset.type === "multipack")
                {
                    this.mergeMultipack (asset, resources);
                }
                else if (asset.type === "multipack-animation")
                {
                   this.mergeMultipack (asset, resources, asset.anim);
                }

            }
        }
    }




    onGroupLoaded (arrAssets, loader, resources)
    {

        this.onGroupLoadedAction(arrAssets, resources);


        Library.setResources(resources);
        this.emit("group-loaded", resources);


    }

    onLoad (loader, loaded)
    {
        //console.log("PIXILoader::onLoad", loaded.url);

    }

    onProgress ()
    {
        //console.log("PIXILoader::onProgress", arguments);
    }




}