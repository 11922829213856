export default class Lang
{
    constructor ()
    {
        if (Lang.instance)
        {
            throw("Don't instantiate Lang directly");
        }

        Lang.instance = this;

        let arrAcceptedLang = ["fr", "en"];

        let storageLang = window.localStorage.getItem("lang");
        let lang;

        if (arrAcceptedLang.includes(storageLang))
        {
            lang = storageLang;
        }

        if (!lang)
        {

            let pageLang = location.hash.replace("#", "");


            if (arrAcceptedLang.includes(pageLang))
            {
                lang = pageLang;
            }


            if (!lang)
            {
                let documentLang  = document.documentElement.lang;

                if (arrAcceptedLang.includes(documentLang))
                {
                    lang = documentLang;
                }
            }


            if (!lang)
            {
                lang = "en";
            }

            window.localStorage.setItem("lang", lang);

        }

        this.currentLang = lang;
        document.documentElement.lang = lang;

    }

    static toggleLang ()
    {
        Lang.lang = Lang.lang === "en" ? "fr" : "en";

        return Lang.lang;
    }

    static get lang ()
    {
        return Lang.instance.currentLang;
    }

    static set lang (strLang)
    {
        Lang.instance.currentLang = strLang;
        window.localStorage.setItem("lang", strLang);
    }
}

let __lang__ = new Lang();